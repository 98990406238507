import { useMediaQuery } from "react-responsive";
import ColorBlock from "../components/ColorBlock";
import MailingListWrapper from "../components/MailingListWrapper";
import ModelOutputExamples from "../components/ModelOutputExamples";
import PageTitle from "../components/PageTitle";
import Section from "../components/Section";
import ValsPage from "../components/ValsPage";
import Leaderboard from "../components/leaderboard";
import ModelCard from "../components/modelCard";
import taxEvalData from "../data/results/taxeval_results.json";
import { fadeIn } from "../util/animations";

const TakeawaysSection = () => {
  return (
    <Section title="Key Takeaways" id="key-takeaways">
      <ul className="takeaways-list">
        <li>
          Tax questions are a very challenging domain for Large Language Models.
          The default models struggled across the board, but especially with
          math reasoning tasks.
        </li>
        <li>
          Of the models, Sonnet 3.5 performed the best on the multiple choice,
          and GPT-4o and Sonnet 3.5 were tied on the free response. Both still
          struggled to provide accurate tax estimates when multiple-choice
          options were not given.
        </li>
        <li>
          Apart from Llama 3/3.1, open-source models performed only marginally
          better than random guessing. It will take considerable work for these
          models to perform at a high standard on tax reasoning questions.
        </li>
      </ul>
    </Section>
  );
};

const BestModels = () => {
  return (
    <Section title="Highest Quality Models" id="highest-quality-models">
      <div className="best-models">
        {fadeIn(
          <ModelCard
            name={"Claude 3.5 Sonnet"}
            icon={"logos/anthropic.png"}
            color={"accentgreen"}
            acc={61.2}
            costIn={3}
            costOut={15}
            latency={0.63}
            desc={
              "Anthropic's recently released Claude 3.5 Sonnet bests GPT-4o by 2 percentage points on Multiple Choice, and ties it on the free response; It also is significantly cheaper and faster than Opus.;  Even still, the model achieves a low objective accuracy indicating it cannot be used directly for tax applications."
            }
          />,
          75,
          75
        )}
        {fadeIn(
          <ModelCard
            name={"GPT-4o"}
            icon={"logos/oai.png"}
            color={"accentgreen"}
            acc={61.2}
            costIn={5}
            costOut={15}
            latency={0.43}
            desc={
              "GPT-4 and Sonnet are ahead of the rest of the pack, there is a 20 percentage-point gap between them and the next model from a different provider; In the free response category, GPT 4o does reasonably well on recall questions: those asking for rule or term definitions.; While GPT 4o performs well, there seem to be some significant challenges in mathematical reasoning."
            }
          />,
          75,
          75
        )}
      </div>
    </Section>
  );
};

const Context = () => {
  return (
    <Section title="Context" id="context">
      <div className="context">
        <p className="text-section">
          There has been a considerable effort to measure language model
          performance in academic tasks and chatbot settings but these
          high-level benchmarks are contrived and not applicable to specific
          industry use cases. Further, model performance results released by LLM
          providers are highly biased - they are often manufactured to show
          state-of-the-art results.
        </p>
        <p className="text-section">
          Here we start to remedy this by reporting our third-party,
          application-specific findings and live leaderboard results on TaxEval.
          This dataset consists of multiple-choice and free-response US tax
          questions. Some of the major practice areas explored are as follows.
        </p>
        <ColorBlock color="violet">
          <ol className="text-section">
            <li>
              Income Tax:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Taxable income calculation: Understanding the differences
                  between accounting income and taxable income, including
                  permanent and temporary differences.
                </li>

                <li>
                  Tax rates: Applying the appropriate tax rates to calculate
                  income tax expense.
                </li>

                <li>
                  Deferred tax assets and liabilities: Recognizing and measuring
                  deferred tax assets and liabilities arising from temporary
                  differences.
                </li>

                <li>
                  Effective tax rate: Calculating and analyzing the effective
                  tax rate.
                </li>
              </ol>
            </li>
            <li>
              General Tax Concepts:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Matching principle: Applying the matching principle to
                  recognize tax expense in the same period as the related
                  revenue or expense.
                </li>

                <li>
                  Tax accounting methods: Understanding the differences between
                  cash-basis and accrual-basis accounting for tax purposes.
                </li>

                <li>
                  Discontinued operations: Calculating the after-tax gain or
                  loss on disposal of a discontinued operation.
                </li>

                <li>
                  Intangible assets: Understanding the tax implications of
                  impairment losses on intangible assets.
                </li>
              </ol>
            </li>
          </ol>
        </ColorBlock>
        <MailingListWrapper />
      </div>
    </Section>
  );
};

const OverallResults = () => {
  const isDesktop = useMediaQuery({ minWidth: 600 });
  return (
    <Section title="Overall Results" id="overall-results">
      <>
        <p className="text-section pb-4">
          The results per question type are summarized in the graph below.
        </p>
        {isDesktop && (
          <iframe
            src="plots/taxeval/all_results_bar.html"
            title="Embedded HTML"
            width="100%"
            height="620px"
            className=""
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/taxeval/all_results_bar_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          There is a significant divide between the Anthropic/OpenAI models and
          the rest, particularly on the free-response questions. Gemini Pro and
          the GPT 3.5 models were of middling performance. The other open source
          models were hopeless with accuracies near to pure guessing.
        </p>
        <br />
        <p className="text-section">
          Llama 3.1 405B performed well, but was not at the same level as Opus
          or GPT-4o on this task. However, it was competitive, and a significant
          step up from both the previous Llama generations and other open source
          models. And even the Llama 2 models outcompeted Cohere.
        </p>
        <br />
        <p className="text-section">
          DBRX and Command R / R+ performed decently on the multiple-choice
          models -- DBRX outperforms GPT 3.5, and Command R+ performed
          similarly. On the free response, Command R+ also performed similarly
          to GPT 3.5 (middling performance). DBRX performed reasonably well, but
          Llama-70b achieved the same performance for cheaper.
        </p>
        {isDesktop && (
          <iframe
            src="plots/taxeval/acc_vs_cost.html"
            title="Embedded HTML"
            width="100%"
            height="700px"
            className="pb-4 pt-6"
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/taxeval/acc_vs_cost_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          The models that define the Pareto curve are Mistral-7b, GPT-3.5, and
          Claude 3.5 Sonnet. The models follow a roughly logarithmic
          relationship between cost and accuracy, although it will be hard for
          the models below the Pareto curve to compete. Some models, like
          Command R+, have especially poor accuracy for their cost. The
          open-source models have similar performance and cost, whereas there is
          more diversity in the proprietary models.
        </p>
      </>
    </Section>
  );
};

const NotableMentions = () => {
  return (
    <Section title="Notable Mentions" id="notable-mentions">
      {fadeIn(
        <ModelCard
          name={"Meta's Llama-2"}
          icon={"logos/meta.png"}
          color={"accentblue"}
          acc={59.4}
          costIn={0.9}
          costOut={0.9}
          latency={13.05}
          desc={
            "The Llama 70b model was the best open-source model across all categories.; Significant improvements to this model can likely be made by fine-tuning it on legal-specific data; Groups interested in the highest levels of data privacy would find value in running this model on their own infrastructure.; In some instances, the Llama output was correct but did not follow the format instructions provided in prompts."
          }
        />,
        100
      )}
    </Section>
  );
};
const Methodology = () => {
  return (
    <Section title="Methodology" id="methodology">
      <div className="methodology">
        <ColorBlock color="beige">
          <p className="text-section">
            These experiments were run over a broad data set of open tax domain
            questions, courtesy of Daniel Gross. These questions are quite
            challenging, including multiple sub-parts, multi-step calculations,
            and knowledge of tax law application. The original dataset was
            composed multiple-choice questions, with four options to choose from
            each. However, real applications will not be multiple choice.
            Therefore, we also created a version of the dataset in which models
            are prompted to give the answer as an open-ended free response text.
          </p>
          <p className="text-section">
            The multiple-choice answers were parsed and evaluated directly on
            accuracy. Note that a naive random model would have scored a 25%
            accuracy on the multiple-choice section.
          </p>
          <p className="text-section">
            For the free-response questions, we used an LLM-based
            auto-evaluation method to replace human review. This system judged
            whether the generated answer met the standard provided by the right
            answer. Reducing or eliminating human review costs allows for the
            creation of many additional open-form-response tasks, widening the
            range of possible future evaluations.
          </p>

          <p className="text-section">
            Closed source models were accessed using their respective APIs. For
            all open-source model evaluation, we make use of TogetherAI
            inference endpoints. Cost and latency may vary between providers but
            this benchmark can be used to compare relative quality-cost-latency
            tradeoffs.
          </p>

          <p className="text-section">
            Each API request was retried four times with exponential backoff to
            eliminate transient errors in inference APIs.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const ModelExamples = () => {
  return (
    <Section title="Model Output Examples" id="model-examples">
      <p className="text-section">
        We show here one free response question, in which Opus and Gemini were
        incorrect, while GPT4 was correct. This question relates to what type of
        fund a library should use.
      </p>
      <br />
      <p className="text-section">
        This question is tricky, because both answers could be right, but an
        enterprise fund is a <em>better</em> answer. Enterprise funds are
        intended to be used for services that are wholly self sufficient.
        Furthermore, enterprise funds provide accrual accounting, which is
        useful for tracking revenue and expenses. Also, special revenue funds
        can only be used for a specific purpose, meaning if there is overage,
        the fund remains locked.
      </p>
      <br />
      <p className="text-section">
        Gemini just says "Governmental fund", which is a broad category of
        funds, rather than the specific type (Special revenue or Enterprise).
        However, this would not be useful to a user, as they would be interested
        in what type of governmental fund they should use.
      </p>
      <br />
      <ColorBlock color="beige">
        <p className="text-section">
          Prompt: Answer concisely in one word, phrase or number. King City
          Council will be establishing a library fund. Library fees are expected
          to cover 55% of the library's annual resource requirements. King has
          decided that an annual determination of net income is desirable in
          order to maintain management control and accountability over library.
          What type of fund should King establish in order to meet their
          measurement objectives?
        </p>
      </ColorBlock>
      <ModelOutputExamples names={["Opus", "GPT-4", "Gemini 1.0"]}>
        <>
          <p className="text-section">
            <strong>Opus: &nbsp;</strong>Special revenue fund.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>GPT 4: &nbsp;</strong>Enterprise fund
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Gemini 1.0: &nbsp;</strong>Governmental
          </p>
        </>
      </ModelOutputExamples>
    </Section>
  );
};

export default function TaxEval() {
  return (
    <ValsPage pagename="taxeval">
      <PageTitle
        title="TaxEval"
        subtitle={
          <span>Evaluating Language Models on Tax Domain Questions</span>
        }
      />
      <Leaderboard
        modelData={taxEvalData}
        defaultSelection="free_response"
        // Months are 0 indexed for god knows what reason

        lastUpdated={new Date(2024, 6, 25)}
      />
      <div className="page-content-container">
        <TakeawaysSection />
        <BestModels />
        <Context />
        <OverallResults />
        {/* <NotableMentions /> */}
        {/* <Quirks /> */}
        <Methodology />
        <ModelExamples />
      </div>
    </ValsPage>
  );
}
