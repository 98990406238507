import { useMediaQuery } from "react-responsive";
import ColorBlock from "../components/ColorBlock";
import MailingListWrapper from "../components/MailingListWrapper";
import ModelOutputExamples from "../components/ModelOutputExamples";
import PageTitle from "../components/PageTitle";
import Section from "../components/Section";
import ValsPage from "../components/ValsPage";
import Leaderboard from "../components/leaderboard";
import ModelCard from "../components/modelCard";
import contractLawData from "../data/results/contractlaw_results.json";
import { fadeIn } from "../util/animations";

const TakeawaysSection = () => {
  return (
    <Section title="Key Takeaways" id="key-takeaways">
      <ul className="takeaways-list">
        <li>
          Meta's Llama 3.1 405B was the top-performing model - it achieved 75.2%
          accuracy, setting a new SOTA on this task. All of the Llama 3.1 models
          perform particularly on the extraction task.
        </li>
        <li>
          Anthropic's Claude 3 Opus model was second. It showed particular
          strength in determining whether contract language was in accordance
          with firm standards and suggesting corrections.
        </li>
        <li>
          Surprisingly, GPT-4o Mini marginally outperformed its more expensive,
          powerful counterpart, GPT-4. It also was the top-performing model on
          the "matching" subtask.
        </li>

        <li>
          Overall, language models are reasonably capable of performing tasks on
          contract law-related questions for documents of this type. It is
          likely that we will continue to see improvement as new models are
          released.
        </li>
      </ul>
    </Section>
  );
};

const BestModels = () => {
  return (
    <Section title="Highest Quality Models" id="highest-quality-models">
      <div className="best-models">
        {fadeIn(
          <ModelCard
            name={"Llama 3.1 (405b)"}
            icon={"logos/meta.png"}
            color={"accentblue"}
            acc={75.2}
            costIn={5}
            costOut={5}
            latency={2.19}
            desc={
              "The latest Llama-3.1 405B model achieved state of the art on this task.; " +
              "It was particularly good on extraction tasks, achieving the top three spots.; " +
              "Unlike other models, it is priced the same for input and output tokens - and priced higher than the other open source models."
            }
          />,
          100
        )}
        {fadeIn(
          <ModelCard
            name={"Anthropic's Opus"}
            icon={"logos/anthropic.png"}
            color={"accentgreen"}
            acc={74.0}
            costIn={15}
            costOut={75}
            latency={5.97}
            desc={
              "The Opus model was overall the second-best performing model, by accuracy. Unlike in other tasks, it outperformed the newer Sonnet 3.5 model.; " +
              "It especially showed its ability, above other models, to correct contract law language to be in accordance with a standard.; " +
              "Opus did not perform well in extraction questions. It had an equivalent performance to the less-powerful Claude Sonnet."
            }
          />,
          75,
          75
        )}
      </div>
    </Section>
  );
};

const Context = () => {
  return (
    <Section title="Context" id="context">
      <div className="context">
        <p className="text-section">
          There has been a considerable effort to measure language model
          performance in academic tasks and chatbot settings, but these
          high-level benchmarks are contrived and not applicable to specific
          industry use cases. Further, model performance results released by LLM
          providers are highly biased - they are often manufactured to show
          state-of-the-art results.
        </p>
        <p className="text-section">
          Here we start to remedy this by reporting our third-party,
          application-specific findings and live leaderboard results on the
          ContractLaw dataset, which was created in collaboration with{" "}
          <a href="https://speedlegal.io/">SpeedLegal</a>. This dataset consists
          of three task types which all pertain to various contract types. The
          different tasks are as follows.
        </p>
        <ColorBlock color="violet">
          <ol className="text-section">
            <li>
              <strong>Extraction:</strong> Asking the model to retrieve a part
              of the contract that relates to a relevant term. The model must
              understand the legal term within the contract that is being
              searched for and extract the relevant phrase or sentence that
              relates to it. Some extraction terms include "Non-Competition
              Covenant" or "Governing Law".
            </li>
            <li>
              <strong>Matching:</strong> Providing a model with an excerpt of a
              contract and a standard text to determine whether the contract
              upholds the standard expected. When lawyers review legal
              contracts, they determine whether the language is within the
              expectations of their client. Statements that are too risky or
              non-standard should be identified and corrected before contracts
              are signed. Here, the model was asked whether a given statement
              should be flagged.
              {/* For instance, the contract
              text might be "This Agreement shall come into force on the date of
              its signing by both the parties and shall be valid up to One year"
              and the standard text might be "This agreement , and all
              obligations thereof unless otherwise stated in the relevant
              provisions, shall expire after 3 years from the Effective Date".
              The expected output would be "unmatched". */}
            </li>
            <li>
              <strong>Correction:</strong> Given an excerpt of a contract text
              and standard text, the model is asked to correct the contract text
              to meet the standard. This is the fix that a lawyer might write to
              send a new contract to the opposing party for review.
            </li>
          </ol>
        </ColorBlock>
        <p className="text-section">
          These tasks were evaluated over five contract types. These contract
          types were Non-Disclosure Agreements (NDA), Data Processing Agreements
          (DPA), Master Service Agreements (MSA), Sales Agreements, and
          Employment Agreements.
        </p>
        <MailingListWrapper />
      </div>
    </Section>
  );
};

const OverallResults = () => {
  const isDesktop = useMediaQuery({ minWidth: 600 });
  return (
    <Section title="Overall Results" id="overall-results">
      <>
        <p className="text-section pb-4">
          The results per task category are summarized in the graph below.
        </p>
        {isDesktop && (
          <iframe
            src="plots/contractlaw/all_results_bar.html"
            title="Embedded HTML"
            width="100%"
            height="620px"
            className=""
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/contractlaw/all_results_bar_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          Llama 3.1 was the top-performing model on the extraction task, and
          overall. It was also tied for first on the correction task, which was
          clearly the most challenging task overall. It separated Llama, GPT4,
          Claude 3 models, and Gemini from the rest of the pack. It is
          understandable why this task was so challenging because correction
          requires the model to interpret a standard text and generate a novel
          contract text revision.
        </p>
        <br />
        <p className="text-section">
          GPT-4o Mini performed the best on the correction task, followed by
          Opus and Llama 3.1. Despite its small size and low cost, this budget
          model packs a powerful punch, and is very competitive.
        </p>

        <br />
        <p className="text-section">
          GPT-4o performed a few percentage points better than GPT-4 on
          extraction and correction -- this was expected, and matches what we
          see on other tasks. However, it performed extremely poorly on the
          matching task. A deeper look into its outputs showed that it was
          outputting the same answer on almost every prompt -- since the classes
          were balanced, it achieved roughly 50% performance. This brought its
          overall average significantly down.
        </p>
        <br />
        <p className="text-section">
          The Alpaca model was hopeless across the board, not answering
          questions in parsable formats or with any relevance. Mixtral
          consistently performed the best of the open source models.
        </p>
        <br />

        <p className="text-section">
          Gemini 1.5 Pro performs exceedingly well on the Extraction ContractLaw
          tasks -- second to Llama only. It performs reasonably well on matching
          tasks as well (4th). However, it does very poorly on Correction - it
          is much too verbose, rather than cleanly answering the task.
        </p>
        <br />
        <p className="text-section">
          Anthropic's latest Claude 3.5 Sonnet performed a few percentage points
          better than Sonnet 3.0 -- however, we did not see the massive
          performance gains or new SOTAs like on the TaxEval or CorpFin dataset.
          It performed especially poorly on extraction, and middle-of-the-pack
          on the other two tasks.
        </p>
        <br />

        {isDesktop && (
          <iframe
            src="plots/contractlaw/acc_vs_cost.html"
            title="Embedded HTML"
            width="100%"
            height="700px"
            className="pb-4 pt-6"
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/contractlaw/acc_vs_cost_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          Overall, the accuracy-cost scatter plot follows a logarithmic curve:
          the highest-performing models see increasingly diminishing returns for
          their higher costs. The Opus model performs 2% better than the next
          best model, GPT4, but at a 50% higher cost.
        </p>
        <br />

        <p className="text-section">
          There is a cluster of "mid-range" models (Gemini 1.5, Sonnet 3/3.5,
          and R+) that all performed very similarly, at a very close price
          point. Although Gemini is slightly more expensive than the other two
          for input tokens, it is much cheaper for output tokens ($7 / M
          compared to $15 / M).
        </p>
        <br />
        <p className="text-section">
          Interestingly enough, Llama 3.1 is actually <em>more</em> expensive
          than Claude 3.5 Sonnet (for input tokens), and matches GPT-4o. This
          coincides with Meta marketing it as the first "premium" open-source
          model - it is priced as one. This is also in-line with the model's
          size, it is significantly larger than previous open-source models.
        </p>
        <br />

        <p className="text-section">
          Within the "budget" models, GPT-4o Mini is the clear winner -
          significantly cheaper and better than any other lightweight or
          midweight model.
        </p>
        <br />
      </>
    </Section>
  );
};

const NotableMentions = () => {
  return (
    <Section title="Notable Mentions" id="notable-mentions">
      {fadeIn(
        <ModelCard
          name={"GPT-4o Mini"}
          icon={"logos/oai.png"}
          color={"accentgreen"}
          acc={72.4}
          costIn={0.15}
          costOut={0.6}
          latency={1.92}
          desc={
            "GPT4o Mini bested its larger counterpart, at an extremely cheap price point. It achieved a fourth-place ranking.; " +
            "It handily beat out all of the open-source models aside from Llama 405B - we may see a trend that open-source models are no longer the best 'budget' option.; " +
            "GPT4's major deficit was in its ability to correct contract language to meet a desired standard."
          }
        />,
        75,
        75
      )}
    </Section>
  );
};

const Quirks = () => {
  return (
    <Section title="Quirks" id="quirks">
      <div className="quirks">
        <ColorBlock color="green">
          <p className="text-section">
            <strong>Gemini: </strong> The Gemini documentation and error
            handling were extremely poor. Even after turning content moderation
            to the least restrictive setting, the API frequently returned an
            “unsafe content” error. In some cases, we could circumvent this
            because the error payload included the supposedly unsafe generation
          </p>

          <p className="text-section">
            Often, we’d get an index out-of-bounds error originating from within
            Gemini’s own Python SDK, instead of a more meaningful error message.
            We debugged further, now believing this to be an additional level of
            content moderation not exposed to the user in any capacity.
          </p>

          <p className="text-section">
            In general, Gemini has much stricter content moderation on its
            output than most other models. This is true even if Content Blocking
            was set to the lowest possible settings. Because of this, the model
            was not able to successfully produce outputs for many tests in the
            learned_hands tasks and others. These cases are treated as failures.
            The Gemini Pro model results may improve considerably if the content
            moderation is better calibrated.
          </p>

          <p className="text-section">
            Gemini Pro pricing is per character, not per token. We go by the
            pricing{" "}
            <a href="https://ai.google.dev/pricing" className="underline">
              listed here
            </a>
            , and assume an average of 4 characters per token.
          </p>
        </ColorBlock>

        <ColorBlock color="violet">
          <p className="text-section">
            <strong>Falcon: </strong>Falcon has a lower context window (2048
            tokens) than all of the other models tested. Because of this, we had
            to remove some of the in-context examples for Falcon for eight
            tasks. The longest in-context examples were removed first, and
            examples were removed from each class such that the classes remained
            balanced.
          </p>

          <p className="text-section">
            <strong>Alpaca: </strong>We found that Alpaca performed much better
            on tasks when we used the prompt template `{"{PROMPT}"}”`, rather
            than the recommended prompt of `
            {"### Instruction:\\n{PROMPT}\\n### Response:\\n"}`.
          </p>

          <p className="text-section">
            <strong>Muad Tasks: </strong>For these tasks, all models were liable
            to produce outputs such as “Option A”, instead of just “A”, “B”,
            etc. Therefore, we implemented an additional regex parser to remove
            the unnecessary “Option” token. This was done uniformly across
            tasks.
          </p>
        </ColorBlock>

        <ColorBlock color="rose">
          <p className="text-section">
            <strong>Claude: </strong>Almost all LegalBench tasks are
            multiple-choice, and expect a single word or choice as an output.
            Claude-2 has extreme difficulty producing outputs in this format.
            Even if explicit instructions are provided, such as “Don’t include
            an explanation for your choice”, “Answer in a single word only”,
            etc., the model reliably produced a paragraph-length output with
            some explanation.
          </p>

          <p className="text-section">
            To give the model a chance, we wrote a custom parser for Claude. We
            asked it to produce outputs in a JSON format with ‘explanation’ and
            ‘answer’ keys, then extracted the ‘answer’ key.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const ModelExamples = () => {
  return (
    <Section title="Model Output Examples" id="model-examples">
      <div className="model-examples">
        <p className="text-section">
          In the following example, we asked a model to take a contract and
          suggest a correction in keeping with the provided standard text. With
          each question, we also provided the model with a few in-context
          examples of ideal corrections.
        </p>
        <br />
        <p className="text-section">
          The challenge with this task is to adapt the existing contract
          language in a way that is in keeping with the standard. Simply
          replacing the text with the standard text does not suffice. Models
          must understand the nuance of the clauses to form a good correction.
        </p>
        <br />
        <p className="text-section">
          For the question asked, the answer we were looking for was "This
          Agreement shall continue for a period of three (3) years from the
          Effective Date or until such time as a definitive agreement(s) is
          entered into by the Parties with respect to the Purpose, whichever
          occurs first."
        </p>
        <br />
        <p className="text-section">
          In particular, it is important for the correction to describe that the
          agreement should continue for a period of three years OR until a
          definitive agreement is reached. Both parts of this logical statement
          must be included.
        </p>
        <br />
        <p className="text-section">
          In the example, we see that Gemini Pro 1.0 and GPT4 are able to
          produce an answer like this while Opus simply reproduces the standard
          text. Llama 3.1 405B also produces an accurate suggested fix, and is
          also more verbose (this is still a pass under our grading system).
        </p>
        <br />
        <ColorBlock color="beige">
          <p>
            <p>
              You are a lawyer reviewing an NDA contract text. Please correct
              the contract text to match the criteria/information included in
              the standard text. Respond with a provision suggested fix.
            </p>
            <br />
            <div className="example">
              <h3>Example 1</h3>
              <p>
                <strong>Text:</strong> The Processor undertakes to carry out
                Data Processing exclusively on the basis of documented
                instructions from the Controller.
              </p>
              <p>
                <strong>Provision Name:</strong> Scope, purpose, and manner of
                the processing
              </p>
              <p>
                <strong>Standard Text:</strong> The Controller will determine
                the scope, purposes, and manner by which the Personal Data may
                be processed.
              </p>
              <p>
                <strong>Provision Suggested Fix:</strong> The Controller will
                determine and document the scope, purposes, and manner by which
                the Personal Data may be processed, and the Processor will carry
                out this processing exclusively based on these instructions.
              </p>
            </div>
            <br />
            <div className="example">
              <h3>Example 2</h3>
              <p>
                <strong>Text:</strong> ...
                <br />
                <strong>Provision Name:</strong> ...
                <br />
                <strong>Standard Text:</strong> ...
                <br />
                <strong>Provision Suggested Fix:</strong> ...
              </p>
            </div>
            <br />

            <p>
              <h3>Question</h3>
              <strong>Text:</strong> This Agreement shall continue for a period
              of one (1) year from the Effective Date or until such time as a
              definitive agreement(s) is entered into by the Parties with
              respect to the Purpose.
            </p>
            <p>
              <strong>Provision Name:</strong> Duration
            </p>
            <p>
              <strong>Standard Text:</strong> This agreement, and all
              obligations thereof unless otherwise stated in the relevant
              provisions, shall expire after 3 years from the Effective Date.
            </p>
            <p>
              <strong>Provision Suggested Fix:</strong> [Suggested fix not
              provided]
            </p>
          </p>
        </ColorBlock>

        <ModelOutputExamples
          names={[
            "Opus",
            "GPT-4",
            "Gemini Pro 1.0",
            "Command R+",
            "Llama 3.1 405B",
          ]}
        >
          <>
            <p className="text-section">
              <strong>Opus: &nbsp;</strong>
              Provision Suggested Fix: This Agreement, and all obligations
              thereof unless otherwise stated in the relevant provisions, shall
              continue for a period of three (3) years from the Effective Date.
            </p>
          </>
          <>
            <p className="text-section">
              <strong>GPT-4: &nbsp;</strong>
              This Agreement shall continue for a period of three (3) years from
              the Effective Date, unless a definitive agreement(s) is entered
              into by the Parties with respect to the Purpose before the
              expiration of this term, at which point this Agreement shall
              expire in accordance with the terms of such definitive
              agreement(s).
            </p>
          </>
          <>
            <p className="text-section">
              <strong>Gemini Pro 1.0: &nbsp;</strong>
              This Agreement shall continue for a period of three (3) years from
              the Effective Date or until such time as a definitive agreement(s)
              is entered into by the Parties with respect to the Purpose.
            </p>
          </>
          <>
            <p className="text-section">
              <strong>Command R+: &nbsp;</strong>
              Provision Suggested Fix: This Agreement shall continue for a
              period of 3 years from the Effective Date, unless a definitive
              agreement is entered into by the Parties with respect to the
              Purpose prior to the end of this term.
            </p>
          </>
          <>
            <p className="text-section">
              <strong>Llama 3.1 405B: &nbsp;</strong>
              Here is the suggested fix: "This agreement, and all obligations
              thereof unless otherwise stated in the relevant provisions, shall
              expire after 3 years from the Effective Date. Notwithstanding the
              foregoing, if an executed contract between Michigan Virtual and
              the Collaborator extends beyond the 3-year period, this Agreement
              will apply for the duration of the executed contract(s)." This fix
              aligns the contract text with the standard text, setting the
              default duration of the agreement to 3 years, while also allowing
              for the possibility of extension if there is an executed contract
              between the parties that goes beyond the 3-year period.
            </p>
          </>
        </ModelOutputExamples>
      </div>
    </Section>
  );
};

const Methodology = () => {
  return (
    <Section title="Methodology" id="methodology">
      <div className="methodology">
        <ColorBlock color="beige">
          <p className="text-section">
            These experiments were run over our ContractLaw dataset which was
            sourced in collaboration with SpeedLegal. The data set consists of
            over 700 contracts of five types (NDAs, DPAs, MSAs, Employment
            Agreements, and Sales Agreements). These contracts were applied for
            three tasks: matching, correction, and extraction. The matching task
            is binary classification while correction and extraction are free
            generation tasks.
          </p>
          <p>
            For the extraction questions, we provided the entire contract to the
            model in the context window. When the output was longer than the
            smallest model's context window (4096 tokens) we trimmed from the
            start and end of the contract while ensuring that the extracted text
            remained within the provided contract.
          </p>

          <p className="text-section">
            Closed source models were accessed using their respective APIs. For
            all open-source model evaluation, we make use of TogetherAI
            inference endpoints. Cost and latency may vary between providers but
            this benchmark can be used to compare relative quality-cost-latency
            tradeoffs. All models were evaluated with their temperature set at
            0.
          </p>

          <p className="text-section">
            The matching task was evaluated using a string-matching binary
            classification. We added additional regex checks that helped reduce
            false negatives caused by improper output formatting, although most
            models conformed to output format expectations.
          </p>

          <p className="text-section">
            We used an LLM-based auto-evaluation platform to replace human
            review on one task, Rule QA. Reducing or eliminating human review
            costs allows for the creation of many additional open-form-response
            tasks, widening the range of possible future evaluations.
          </p>

          <p className="text-section">
            Each API request was retried four times with exponential backoff to
            eliminate transient errors in inference APIs.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const AdditionalNotes = () => {
  return (
    <Section title="Additional Notes" id="additional-notes">
      <div className="additional-notes space-y-4">
        <p className="text-section">
          <strong>Gemini Infrastructure Issues:</strong> While we were testing
          these models, we experienced extended API outages for the Gemini
          models. These uptime issues may be resolved soon, but warrant
          investigating further before using Gemini in any production use.
        </p>
      </div>
    </Section>
  );
};

const Partners = () => {
  let outerStyling =
    "flex justify-center items-center border-[3px] border-[#D0C7BF] rounded-full w-[70px] h-[70px] md:w-[150px] md:h-[150px] md:border-[6px]";
  return (
    <div>
      <h1 className="header" id="partners-in-evaluation">
        Partners in Evaluation
      </h1>
      <div className="flex justify-around pt-2">
        {fadeIn(
          <a
            href="https://law.stanford.edu/codex-the-stanford-center-for-legal-informatics/"
            target="_blank"
          >
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/codex.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          0
        )}
        {fadeIn(
          <a href="https://speedlegal.io/" target="_blank">
            <div className={outerStyling + " bg-[#000000]"}>
              <img
                src={"partner_logos/speedlegal.jpg"}
                className="object-scale-down w-[45px] md:w-[90px]"
              />
            </div>
          </a>,
          200
        )}
        {fadeIn(
          <a href="https://www.together.ai/" target="_blank">
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/tai.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          400
        )}
        {/* <div className={outerStyling}>
            <img
              src={"partner_logos/stanford.png"}
              className="object-scale-down h-[40px]"
            />
          </div> */}
      </div>
    </div>
  );
};

export default function ContractLaw() {
  return (
    <ValsPage pagename="contractlaw">
      <PageTitle
        title="ContractLaw"
        subtitle={
          <span>Benchmarking model performance on Contract Law Tasks</span>
        }
      />
      <Leaderboard
        modelData={contractLawData}
        defaultSelection="overall"
        lastUpdated={new Date(2024, 6, 25)}
      />
      <div className="page-content-container">
        <Partners />
        <TakeawaysSection />
        <BestModels />
        <Context />
        <OverallResults />
        <NotableMentions />
        <ModelExamples />
        {/* <Quirks /> */}
        <AdditionalNotes />
        <Methodology />
      </div>
    </ValsPage>
  );
}
