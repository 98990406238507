import { fadeInUp } from "../util/animations";

export default function ContactUs() {
  return fadeInUp(
    <div className="flex flex-col items-center">
      <p className="font-serif text-2xl text-black pt-12 font-bold">
        Get in Touch
      </p>
      <p className="body text-center italic pt-2">
        Want to see a private report like this on your own data?
      </p>
      <div className="flex mt-8 ">
        <a href="mailto:contact@vals.ai?subject=[Vals.ai Inquiry]: ">
          <button className="bg-accentgreen text-white px-6 h-[45px] rounded-md">
            Contact Us
          </button>
        </a>
      </div>
    </div>
  );
}
