import { useMediaQuery } from "react-responsive";
import ColorBlock from "../components/ColorBlock";
import MailingListWrapper from "../components/MailingListWrapper";
import ModelOutputExamples from "../components/ModelOutputExamples";
import PageTitle from "../components/PageTitle";
import Section from "../components/Section";
import ValsPage from "../components/ValsPage";
import Leaderboard from "../components/leaderboard";
import ModelCard from "../components/modelCard";
import legalBenchData from "../data/results/legalbench_results.json";
import "../styles/basics.css";
import { fadeIn } from "../util/animations";

const TakeawaysSection = () => {
  return (
    <Section title="Key Takeaways" id="key-takeaways">
      <ul className="takeaways-list">
        <li>
          Llama 3.1 Instruct (405B & 70B) is the top-performing model -- beating
          out both the other closed-source and open-source models. It is also
          more expensive than other open-source models for inference, but still
          very competitive on price compared to the closed models.
        </li>
        <li>
          GPT-4o is still second, only a few percentage points behind Llama 3.1.
          However, with the new entries from Anthropic and Meta, the OpenAI
          models are no longer the only top performers.
        </li>
        <li>
          Anthropic's Claude 3 Opus is third place, however, it is three times
          more expensive than GPT-4o for input tokens and five times more
          expensive for output tokens. 3.5 Sonnet is 5th (but only trails Opus
          by 0.3 percentage points).
        </li>
        <li>
          GPT-4o Mini stands out as a great budget model, achieving strong
          performance while being one of the cheapest models available.
        </li>

        <li>
          A given model's performance can vary dramatically across different
          legal tasks. There is still significant room for improvement for these
          models to perform well on legal tasks.
        </li>
      </ul>
    </Section>
  );
};

const BestModels = () => {
  return (
    <Section title="Highest Quality Models" id="highest-quality-models">
      <div className="best-models">
        {fadeIn(
          <ModelCard
            name={"Llama 3.1 (405B)"}
            icon={"logos/meta.png"}
            color={"accentblue"}
            acc={80.5}
            costIn={5}
            costOut={5}
            latency={2.0}
            desc={
              "The latest Llama-3.1 70B model had a standout performance on LegalBench, setting a new state-of-the-art.; " +
              "The model is priced higher than other open-source models, at $5 / MTok for both input and output. This is still in-line with GPT-4o and 3.5 Sonnet though.; " +
              "We will see how the closed-source models respond to this new entry into the foundation model market."
            }
          />,
          100
        )}
        {fadeIn(
          <ModelCard
            name={"Open AI's GPT-4o"}
            icon={"logos/oai.png"}
            color={"accentgreen"}
            acc={78.7}
            costIn={5}
            costOut={15}
            latency={0.37}
            desc={
              "GPT-4o remains a standout performer -- it is still second on LegalBench, and performs the best on many of the subtasks.; " +
              "GPT-4o is 50% of the cost of its predecessor, GPT-4 Turbo. It is still more expensive than models like Sonnet, but only marginally." +
              "After the release of Turbo models, the latency of OpenAI's models has dropped considerably making GPT4o among the fastest models."
            }
          />,
          75,
          75
        )}
      </div>
    </Section>
  );
};

const Context = () => {
  return (
    <Section title="Context" id="context">
      <div className="context">
        <p className="text-section">
          There has been a considerable effort to measure language model
          performance in academic tasks and chatbot settings but these
          high-level benchmarks are not applicable to specific industry use
          cases. Here we start to remedy this by reporting our
          application-specific findings and live leaderboard results on
          LegalBench, a large crowd-sourced collection of legal reasoning tasks.
          The data set is quite comprehensive, covering six major categories.
        </p>
        <ColorBlock color="violet">
          <ol className="text-section">
            <li>
              Issue-spotting, where a model must determine if a fact has
              relevance to a particular law or legal entity.
            </li>
            <li>
              Rule-recall, where a model must identify a relevant rule or state
              its characteristics.
            </li>
            <li>
              Rule-conclusion, where a model must predict a legal outcome.
            </li>
            <li>
              Rule-application, where a model must analyze how a rule was
              applied to reach a conclusion.
            </li>
            <li>
              Interpretation, where a model must parse and understand legal
              text.
            </li>
            <li>
              Rhetorical understanding, where a model must determine whether a
              legal argument performs a certain function.
            </li>
          </ol>
        </ColorBlock>
        <MailingListWrapper />
      </div>
    </Section>
  );
};

const OverallResults = () => {
  const isDesktop = useMediaQuery({ minWidth: 600 });
  return (
    <Section title="Overall Results" id="overall-results">
      <>
        <p className="text-section pb-4">
          The results per task category are summarized in the graph below.
        </p>
        {isDesktop && (
          <iframe
            src="plots/legalbench/all_results_bar.html"
            title="Embedded HTML"
            width="100%"
            height="620px"
            className=""
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/legalbench/all_results_bar_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          The GPT4o, Opus and Llama 3.1 models perform consistently well across
          task categories. Gemini Pro, Claude Sonnet, and Cohere Command R+
          compete for ninth through twelfth on most categories. The open source
          models generally had middling performance, although amongst them, the
          Llama models were significantly better. Performance varies by subtask
          - with most of the per-task top spots being taken by either Llama or
          GPT.
        </p>
        <br />
        <p className="text-section">
          The latest Cohere Command R+ model performed competitively, although
          clearly not yet at the performance of Opus or GPT. The performance of
          the new DBRX model was middling -- performing significantly worse than
          several other existing open source models.
        </p>

        {isDesktop && (
          <iframe
            src="plots/legalbench/acc_vs_cost.html"
            title="Embedded HTML"
            width="100%"
            height="700px"
            className="pb-4 pt-6"
          />
        )}
        {!isDesktop && (
          <iframe
            src="plots/legalbench/acc_vs_cost_mobile.html"
            title="Embedded HTML"
            width="100%"
            height="500px"
            className="pb-4 pt-6"
          />
        )}
        <p className="text-section">
          The cost-accuracy graph shows a few models that define a Pareto curve
          of tradeoffs: GPT-4o mini, Llama 3.1 70B, and Llama 3.1 405B. Shortly
          off the Pareto curve are GPT-4o and Claude 3.5 Sonnet. Among these
          four models, the objective difference in performance is small (a
          matter of a few percentage points), however, they have wildly
          different prices. GPT-4o Mini particularly stands out as having a very
          high quality to price ratio.
        </p>
        <br />

        <p className="text-section">
          Amongst the other models, there is a somewhat logarithmic trend, with
          the more expensive models seeing diminishing returns for marginal
          cost. Also, models of previous "generations" (Claude 2, GPT 4, etc.),
          perform strictly worse for their price than the newer models --
          likely, providers are disincentivizing their use.
        </p>
        <br />

        <p className="text-section">
          The latest Command R+ model performed decently -- it was priced
          identically to Anthropic's Sonnet, but with very slightly higher
          performance. However, Gemini still outperforms both of them, for a
          fraction of the cost.
        </p>
        <br />
        <p className="text-section">
          Gemini 1.5 does not perform significantly better than Gemini 1.0 --
          although its performance is better on certain tasks and categories, it
          performs significantly worse on others. It often is overly verbose, or
          does not understand the in-context examples without additional
          prompting. However, it is still the top 5 models, beating out Command
          R+ and Sonnet.
        </p>
        <br />
      </>
    </Section>
  );
};

const NotableMentions = () => {
  return (
    <Section title="Notable Mentions" id="notable-mentions">
      <div className="space-y-8">
        {fadeIn(
          <ModelCard
            name={"Anthropic's Opus"}
            icon={"logos/anthropic.png"}
            color={"accentgreen"}
            acc={77.7}
            costIn={15}
            costOut={75}
            latency={4.61}
            desc={
              "Opus is the fourth-best performing model on legal reasoning tasks. It shows particular strength in Conclusion and Interpretation tasks.; However, it comes at a a significant cost.; Compared to GPT4o, Opus costs 300% more for input tokens and 500% more for output tokens and takes 13 times as long to respond."
            }
          />,
          75,
          75
        )}
      </div>
    </Section>
  );
};

const Quirks = () => {
  return (
    <Section title="Additional Notes" id="quirks">
      <div className="quirks">
        <ColorBlock color="green">
          <p className="text-section">
            <strong>Gemini: </strong> The Gemini documentation and error
            handling were extremely poor. Even after turning content moderation
            to the least restrictive setting, the API frequently returned an
            “unsafe content” error. In some cases, we could circumvent this
            because the error payload included the supposedly unsafe generation
          </p>

          <p className="text-section">
            Often, we’d get an index out-of-bounds error originating from within
            Gemini’s own Python SDK, instead of a more meaningful error message.
            We debugged further, now believing this to be an additional level of
            content moderation not exposed to the user in any capacity.
          </p>

          <p className="text-section">
            In general, Gemini has much stricter content moderation on its
            output than most other models. This is true even if Content Blocking
            was set to the lowest possible settings. Because of this, the model
            was not able to successfully produce outputs for many tests in the
            learned_hands tasks and others. These cases are treated as failures.
            The Gemini Pro model results may improve considerably if the content
            moderation is better calibrated.
          </p>

          <p className="text-section">
            Gemini Pro pricing is per character, not per token. We go by the
            pricing{" "}
            <a href="https://ai.google.dev/pricing" className="underline">
              listed here
            </a>
            , and assume an average of 4 characters per token.
          </p>
        </ColorBlock>

        <ColorBlock color="violet">
          <p className="text-section">
            <strong>Falcon: </strong>Falcon has a lower context window (2048
            tokens) than all of the other models tested. Because of this, we had
            to remove some of the in-context examples for Falcon for eight
            tasks. The longest in-context examples were removed first, and
            examples were removed from each class such that the classes remained
            balanced. These models were recently deprecated by Together AI and
            will be excluded from our evaluations in the future.
          </p>

          <p className="text-section">
            <strong>Alpaca: </strong>We found that Alpaca performed much better
            on tasks when we used the prompt template `{"{PROMPT}"}”`, rather
            than the recommended prompt of `
            {"### Instruction:\\n{PROMPT}\\n### Response:\\n"}`.
          </p>

          <p className="text-section">
            <strong>Muad Tasks: </strong>For these tasks, all models were liable
            to produce outputs such as “Option A”, instead of just “A”, “B”,
            etc. Therefore, we implemented an additional regex parser to remove
            the unnecessary “Option” token. This was done uniformly across
            tasks.
          </p>
        </ColorBlock>

        <ColorBlock color="rose">
          <p className="text-section">
            <strong>Claude 2: </strong>Almost all LegalBench tasks are
            multiple-choice, and expect a single word or choice as an output.
            Claude-2 has extreme difficulty producing outputs in this format.
            Even if explicit instructions are provided, such as “Don’t include
            an explanation for your choice”, “Answer in a single word only”,
            etc., the model reliably produced a paragraph-length output with
            some explanation.
          </p>

          <p className="text-section">
            To give the model a chance, we wrote a custom parser for Claude 2.
            We asked it to produce outputs in a JSON format with ‘explanation’
            and ‘answer’ keys, then extracted the ‘answer’ key. We did not
            perform this for any subsequent Claude models - Claude 3.0 and 3.5
            were evaluated normally.
          </p>

          <p className="text-section">
            When asked for a single-word response, the Claude 3 models still
            struggled to consistently follow these directions. These problems
            were solved once we provided a system prompt with these
            expectations.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const Methodology = () => {
  return (
    <Section title="Methodology" id="methodology">
      <div className="methodology">
        <ColorBlock color="beige">
          <p className="text-section">
            These experiments were run over the open{" "}
            <a
              href="https://hazyresearch.stanford.edu/legalbench/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              LegalBench dataset
            </a>{" "}
            which consists of 157 distinct legal tasks across 5 broad
            categories. Running these evaluations amounted to making ~80,000 API
            queries and submitting ~40M tokens per model. These tasks primarily
            evaluate LLMs in legal reasoning tasks found in academic settings.
          </p>

          <p className="text-section">
            Closed source models were accessed using their respective APIs. For
            all open-source model evaluation, we make use of TogetherAI
            inference endpoints. Cost and latency may vary between providers but
            this benchmark can be used to compare relative quality-cost-latency
            tradeoffs.
          </p>

          <p className="text-section">
            The majority of the tasks were evaluated based on the methodology
            used in LegalBench, which replicates the HELM “exact match”
            approach. We built upon this by adding additional regex checks that
            helped reduce false negatives caused by improper output formatting.
          </p>

          <p className="text-section">
            However, we additionally used our auto-evaluation platform to
            replace human review on one task, Rule QA. Reducing or eliminating
            human review costs allows for the creation of many additional
            open-form-response tasks, widening the range of possible future
            evaluations.
          </p>

          <p className="text-section">
            Each API request was retried four times with exponential backoff to
            eliminate transient errors in inference APIs.
          </p>
        </ColorBlock>
      </div>
    </Section>
  );
};

const Partners = () => {
  let outerStyling =
    "flex justify-center items-center border-[3px] border-[#D0C7BF] rounded-full w-[70px] h-[70px] md:w-[150px] md:h-[150px] md:border-[6px]";
  return (
    <div>
      <h1 className="header" id="partners-in-evaluation">
        Partners in Evaluation
      </h1>
      <div className="flex justify-around pt-2">
        {fadeIn(
          <a
            href="https://law.stanford.edu/codex-the-stanford-center-for-legal-informatics/"
            target="_blank"
          >
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/codex.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          0
        )}
        {fadeIn(
          <a href="https://lawbeta.github.io/" target="_blank">
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/lawbeta.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          200
        )}
        {fadeIn(
          <a href="https://www.together.ai/" target="_blank">
            <div className={outerStyling + " bg-white"}>
              <img
                src={"partner_logos/tai.png"}
                className="object-scale-down w-[50px] md:w-[120px]"
              />
            </div>
          </a>,
          400
        )}
        {/* <div className={outerStyling}>
            <img
              src={"partner_logos/stanford.png"}
              className="object-scale-down h-[40px]"
            />
          </div> */}
      </div>
    </div>
  );
};

const ModelExamples = () => {
  return (
    <Section title="Model Output Examples" id="model-examples">
      <p className="text-section">
        We show some examples from one of the LegalBench free response tasks
        (Rule QA) here. The models were asked "Where in the Federal Rules of
        Civil Procedure are notice requirements described?"
      </p>
      <br />

      <p className="text-section">
        This question is tricky because both Rules 4 and 5 talk about the
        service process and notice requirements. However, Rule 4 is the main
        rule that defines the first notice in a proceeding, whereas Rule 5 gives
        the procedures for filing pleadings, etc.
      </p>
      <br />

      <p className="text-section">
        This question is also difficult because implicit in the question is that
        it's "notice" of a suit. GPT correctly determines that the question is
        talking about the initial notice, whereas Opus does not. Interestingly,
        Gemini gets the answer wrong, but it is much more terse than the other
        two models. Despite performing best overall, Llama 3.1 makes the same
        mistake as Opus, citing Rule 5 instead of Rule 4.
      </p>

      <br />
      <p className="text-section">
        It's important this is one question from a very large evaluation data
        set and is not representative of overall performance.
      </p>
      <br />
      <ColorBlock color="beige">
        <p className="text-section">
          Q: Where in the Federal Rules of Civil Procedure are notice
          requirements described? <br />
          A:
        </p>
      </ColorBlock>
      <ModelOutputExamples
        names={["Opus", "GPT-4", "Gemini Pro 1.0", "Llama 3.1 405B"]}
      >
        <>
          <p className="text-section">
            <strong>Opus: &nbsp;</strong>
            The notice requirements in federal civil litigation are primarily
            described in Rule 5 of the Federal Rules of Civil Procedure (FRCP).
            Specifically: 1. Rule 5(a) outlines the service requirements for
            pleadings and other papers, stating that unless the rules provide
            otherwise, each paper must be served on every party. 2. Rule 5(b)
            describes how service can be made, including the methods of service
            and the permissible means of electronic service. 3. Rule 5(c) states
            that all papers after the complaint required to be served must be
            filed with the court within a reasonable time after service. 4. Rule
            5(d) covers the filing requirements, including electronic filing and
            the filing of discovery materials. Additionally, other rules in the
            FRCP may contain specific notice requirements for certain
            situations, such as Rule 4 for serving a summons and complaint, Rule
            6 for computing and extending time, and Rule 55 for default
            judgments.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>GPT 4: &nbsp;</strong>
            The notice requirements in the Federal Rules of Civil Procedure are
            primarily described in Rule 4, which deals with the summons. Rule 4
            outlines the procedures for issuing a summons, serving the summons
            and complaint, and what constitutes sufficient service of process.
            This rule is crucial for ensuring that a defendant is properly
            notified of the legal action against them, which is a fundamental
            aspect of due process. Additionally, other rules and sections within
            the Federal Rules of Civil Procedure also discuss notice
            requirements in various contexts, but Rule 4 is the primary rule
            concerning the initial notice to a defendant through the summons.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Gemini 1.0: &nbsp;</strong>
            Rule 4.
          </p>
        </>
        <>
          <p className="text-section">
            <strong>Llama-3.1 405B: &nbsp;</strong>
            A: The notice requirements are described in Rule 5 of the Federal
            Rules of Civil Procedure.
          </p>
        </>
      </ModelOutputExamples>
    </Section>
  );
};

function LegalBench() {
  return (
    <ValsPage pagename="legalbench">
      <PageTitle
        title="LegalBench"
        subtitle={
          <span>
            Evaluating language models on a wide range of <br /> open source
            legal reasoning tasks.
          </span>
        }
      />
      <Leaderboard
        modelData={legalBenchData}
        defaultSelection="overall"
        // Months are 0 indexed for god knows what reason
        lastUpdated={new Date(2024, 6, 25)}
      />
      <div className="space-y-10 pt-28">
        <Partners />
        <TakeawaysSection />
        <BestModels />
        <Context />
        <OverallResults />
        <NotableMentions />
        <ModelExamples />
        <Quirks />
        <Methodology />
      </div>
    </ValsPage>
  );
}

export default LegalBench;
