import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import "../styles/basics.css";
import { fadeIn, fadeInUp } from "../util/animations";

const Partners = () => {
  let outerStyling =
    "flex justify-center items-center border-[3px] border-[#D0C7BF] rounded-full w-[70px] h-[70px] md:w-[100px] md:h-[100px] md:border-[6px]";
  return (
    <div>
      <div className="flex justify-around pt-2 space-x-8 pt-8">
        {fadeIn(
          <div className={outerStyling + " bg-white"}>
            <img
              src={"partner_logos/stanford.png"}
              className="object-scale-down h-[50px]"
            />
          </div>,
          0
        )}
        {fadeIn(
          <div className={outerStyling + " bg-white"}>
            <img
              src={"partner_logos/codex.png"}
              className="object-scale-down w-[50px] md:w-[60px]"
            />
          </div>,
          200
        )}
        {fadeIn(
          <div className={outerStyling + " bg-white"}>
            <img
              src={"partner_logos/tai.png"}
              className="object-scale-down w-[50px] md:w-[80px]"
            />
          </div>,
          400
        )}
        {/* <div className={outerStyling}>
            <img
              src={"partner_logos/stanford.png"}
              className="object-scale-down h-[40px]"
            />
          </div> */}
      </div>
    </div>
  );
};

export default function LandingPage() {
  const isDesktop = useMediaQuery({ minWidth: 600 });
  const navigate = useNavigate();
  const tabStyle =
    "w-[150px] h-[50px] md:w-[150px] md:h-[50px] bg-white border-2 md:border-4 border-black flex justify-center items-center mt-2";
  const gridStyle = isDesktop ? "flex space-x-2" : "grid grid-cols-2 gap-4";

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex flex-col items-center">
        <h1 className="font-[inconsolata] font-bold mb-0 p-0 leading-none text-8xl xs:text-7xl">
          vals.ai
        </h1>
        {fadeInUp(
          <p className="mt-4 font-serif  text-2xl  xs:text-xl">
            Public Enterprise LLM Benchmarks
          </p>
        )}
        <div className={"pt-12 " + gridStyle}>
          <button
            className={tabStyle}
            onClick={() => {
              navigate("/legalbench");
            }}
          >
            LegalBench
          </button>
          <button
            className={tabStyle}
            onClick={() => {
              navigate("/contractlaw");
            }}
          >
            ContractLaw
          </button>
          <button
            className={tabStyle}
            onClick={() => {
              navigate("/taxeval");
            }}
          >
            TaxEval
          </button>
          <button
            className={tabStyle}
            onClick={() => {
              navigate("/corpfin");
            }}
          >
            CorpFin
          </button>
          {/* <button
            className={tabStyle}
            onClick={() => {
              navigate("/caselaw");
            }}
          >
            CaseLaw
          </button> */}
        </div>
        <Partners />
      </div>
    </div>
  );
}
